<template>
  <div class="h-100">
    <div class="w-100">
      <div class="flex row align-center">
        <span class="m-r-10 font-14">类型:</span>
        <SelectWithError
            class="m-b-10 m-t-10 flex-1 "
            :label="form.type.label"
            :error-info="errorInfo.type"
            :row-info.sync="form.type"
            @validate="validateRow"
            :options="typeOptions"
            :key="form.type.updateKey"
        />
      </div>
      <div class="flex row align-center">
        <span class="m-r-10 font-14">标题:</span>
        <InputWithError
            class="m-b-10 m-t-10 flex-1"
            :label="form.title.label"
            :error-info="errorInfo.title"
            :row-info.sync="form.title"
            @validate="validateRow"
            :key="form.title.updateKey"
        />
      </div>
    </div>
    <div class="w-100 flex p-t-10 editor-box">
      <span class="m-r-10 font-14">内容</span>
      <editor-bar
          class="editor flex-1"
          style="min-height: 200px;background-color: #f6f6f6;"
          v-model="content.content" @change="change">
      </editor-bar>
    </div>

    <div class="footer m-t-10 p-l-25">
      <el-button :loading="loading" type="primary" style="height: 40px;margin-left: 10px" @click="submit">确认提交</el-button>
    </div>
  </div>
</template>

<script>
import EditorBar from "@components/common/editoritem";
import {getContent, editContent, addContent} from "@/api/content"
import InputWithError from "@components/Form/InputWithError";
import SelectWithError from "@components/Form/SelectWithError";
import {isRequire} from "@/util/validator";
import {isEmpty, omit} from "@/util/lodashUtil";
export default {
  components: {
    EditorBar,
    InputWithError,
    SelectWithError
  },
  name: "EditContent",
  data() {
    return {
      typeMap: {
        "1":'关于我们',
        '2':'用户协议',
        '3':'隐私政策',
        '4':'联系我们',
        '5':'开播教程',
        '6':'开播工具',
        '7':'直播规范',
        '8':'直播协议',
        '9':'注销协议',
      },
      id: 1, // 默认是关于我们
      contentType: 1,
      loading:false,
      content: {},
      form: {
        type: {
          label: '类型',
          value: null,
          key: 'type',
          validators: [isRequire('类型')],
          validateLabel: ['isRequire'],
          updateKey: 'type-false'
        },
        title: {
          label: '标题',
          value: null,
          key: 'title',
          validators: [isRequire('标题')],
          validateLabel: ['isRequire'],
          updateKey: 'title-false'
        }
      },
      errorInfo: {
        type: {},
        title: {}
      }
    }
  },
  computed: {
    isAdd() {
      return !this.id
    },
    typeOptions() {
     return  Object.keys(this.typeMap).reduce((all, key) => {
        all.push({
          id: key * 1,
          value: key,
          label: this.typeMap[key]
        })
        return all
      }, [])
    }
  },
  created() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getContent()
    }
  },
  methods: {
    async getContent() {
      let {data} = await getContent(this.id)
      if (data.code == 200) {
        this.content = data.data
        this.initForm()
        delete this.content.update_time
        delete this.content.is_show
        delete this.content.jump_url
      }
      this.loading = false
    },
    initForm() {
      this.form.title.value = this.content.title
      this.form.type.value = this.typeMap[this.content.type]
    },
    submit() {
      const isValidate = this.validate()
      if(isValidate) {
        const params = {
          ...this.content,
          title: this.form.title.value,
          type: this.form.type.value * 1,
        }
        this.editContent(params)
      }
    },
    async editContent(params) {
      this.loading = true
      const request = this.isAdd ? addContent : editContent
      let {data} = await request(params)
      if (data.code === 200) {
        this.$message({
          message: data.msg,
          type: 'success',
          duration: 1000
        })
        this.$router.push({
          name: 'ContentList'
        })
      }
    },
    change() {

    },
    validate () {
      const res = []
      Object.keys(this.form).forEach(key => {
        res.push(this.validateRow(key))
      })
      return res.every(x => x)
    },
    validateRow (key) {
      this.form[key].validators.forEach(validator => {
        const { message, type } = validator(this.form[key].value)
        if (message) {
          this.errorInfo[key][type] = message
        } else {
          this.errorInfo[key] = omit(this.errorInfo[key], type, message)
        }
      })
      this.changeKey(key)
      return isEmpty(this.errorInfo[key])
    },
    changeKey (key) {
      const flag = JSON.parse(this.form[key].updateKey.split('-')[1])
      this.form[key].updateKey = `${key}-${!flag}`
    },
  }
}
</script>

<style lang="scss" scoped>
.editor-box {
  height: calc(100% - 180px);
}
.editor{
  min-height: 350px;
}
.row {
  width: 500px;
}

::v-deep {
  .input-section {
    background-color: #fff!important;
    border: 1px solid #eee;
    border-radius: 3px;
  }
}
</style>
